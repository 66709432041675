import { IonCard, IonCardContent, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { TIME_FORMAT } from "@src/app/dayView/constants";
import { LicenseTag } from "@src/app/licenseManager/components/licenseTag";
import { useWorkerQualifications } from "@src/app/licenseManager/hooks/useWorkerQualifications";
import { getShiftFacilityDistanceText } from "@src/app/utils/shiftHelper";
import { useIsExtraTimePayEnabled } from "@src/appV2/Facilities/api/useIsExtraTimePayEnabled";
import { getDaylightSavingsTimeIndicator } from "@src/appV2/Shifts/MyShifts/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { Facility, Shift } from "@src/lib/interface/src";
import {
  FULL_ZONE_NAMES,
  getHumanReadableTime,
  getOffsetFormattedTextBetweenTimezones,
  timeRangeFormatter,
} from "@src/lib/utils";
import { homeOutline, stopwatchOutline } from "ionicons/icons";
import moment from "moment-timezone";
import { FC, useMemo } from "react";

import { INegotiationShiftsCardProps } from "./interfaces";
import { NegotiationButton } from "./negotiationButton";

export const RateNegotiationShiftDetails: FC<{ shift: Shift }> = ({ shift }) => {
  /**
   * FIXME! moment.fn.zoneName is being redefined here
   * This mutates a global import, which means this can silently affect other callers with
   * no clear visibility about where this functionality came from.
   * Note that this would be changed at runtime, and only when this component renders.
   * It is redefined on each iteration.
   */
  moment.fn.zoneName = function () {
    const abbr = this.zoneAbbr();
    return FULL_ZONE_NAMES[abbr] || abbr;
  };
  const worker = useDefinedWorker();
  const { tmz: facilityTimezone } = shift.facility as Facility;

  const isExtraTimePayEnabled = useIsExtraTimePayEnabled(shift.facility);

  const { tmz: agentTimezone } = worker;

  const workerQualifications = useWorkerQualifications();

  const shiftFacilityDistanceText = getShiftFacilityDistanceText(shift, worker, false);

  const shiftDateTimeDetails = useMemo(() => {
    const shiftDateInformationText = moment(shift.start)
      .tz(String(facilityTimezone))
      .format("dddd, MMM DD");
    const shiftTimeInformationText = `${timeRangeFormatter(
      TIME_FORMAT,
      {
        start: shift.start as string,
        end: shift.end as string,
      },
      facilityTimezone
    )} (${getHumanReadableTime(shift.time as number)})`;
    return { shiftDateInformationText, shiftTimeInformationText };
  }, [shift, facilityTimezone]);

  const daylightSavingsTimeIndicator = getDaylightSavingsTimeIndicator({
    shift: { start: shift.start as string, end: shift.end as string },
    timeZone: agentTimezone,
  });

  return (
    <div className="negotiation-shift-details-container">
      <IonRow>
        <IonCol size="12">
          <IonText>
            <p className="ion-text-wrap negotiation-shift-details-date-text">
              {shiftDateTimeDetails.shiftDateInformationText}
            </p>
          </IonText>
          <IonText>
            <p className="ion-text-wrap negotiation-shift-details-time-text">
              {shiftDateTimeDetails.shiftTimeInformationText}
            </p>
          </IonText>
          {daylightSavingsTimeIndicator && (
            <IonText>
              <p className="ion-text-wrap negotiation-shift-details-timezone-diff-text">
                {daylightSavingsTimeIndicator}
              </p>
            </IonText>
          )}

          {agentTimezone &&
            facilityTimezone &&
            moment(shift.start).tz(agentTimezone).utcOffset() !==
              moment(shift.start).tz(facilityTimezone).utcOffset() && (
              <IonText>
                <p className="ion-text-wrap negotiation-shift-details-timezone-diff-text">
                  {`${moment()
                    .tz(facilityTimezone)
                    .zoneName()} (${getOffsetFormattedTextBetweenTimezones(
                    shift.start as string,
                    agentTimezone as string,
                    facilityTimezone as string
                  )})`}
                </p>
              </IonText>
            )}

          {workerQualifications?.length > 1 && <LicenseTag agentReq={shift?.agentReq || ""} />}
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12" className="negotiation-shift-details__description">
          <IonText>
            <h3>{shift?.facility?.name}</h3>
          </IonText>
          <IonText>
            <p className="address-distance-text">{shift?.facility?.fullAddress?.formatted}</p>
          </IonText>
          <IonRow className="etp-dist-video-wrapper ion-align-items-center">
            {shiftFacilityDistanceText && (
              <IonText>
                <p className="address-distance-text">
                  <IonIcon className="icon distance-icon" icon={homeOutline} />
                  {shiftFacilityDistanceText}
                </p>
              </IonText>
            )}
            {isExtraTimePayEnabled && (
              <p className="facility-info-link">
                <IonIcon size="small" icon={stopwatchOutline} /> Extra Time Pay Facility
              </p>
            )}
          </IonRow>
        </IonCol>
      </IonRow>
    </div>
  );
};

export const RateNegotiatingShiftsCard: FC<INegotiationShiftsCardProps> = ({
  negotiationShift,
  fetchNegotiatingShifts,
  shouldOpenNegotiationTool,
  shiftBookability,
  isLoadingShiftBookability,
}) => {
  const worker = useDefinedWorker();

  return (
    <IonCard>
      <IonCardContent>
        <RateNegotiationShiftDetails shift={negotiationShift} />
        <NegotiationButton
          shiftDetails={negotiationShift}
          workApproved={negotiationShift.agentId === worker.userId}
          updateShiftViewRateNegotiation={() => {}}
          isLoadingShiftBook={isLoadingShiftBookability}
          shouldOpenNegotiationTool={shouldOpenNegotiationTool}
          isRateNegotiationActive={true}
          onBookShift={(_shift) => {
            fetchNegotiatingShifts();
          }}
          shiftBookability={shiftBookability}
        />
      </IonCardContent>
    </IonCard>
  );
};
