import { PushNotificationSchema, PushNotifications, Token } from "@capacitor/push-notifications";
import { toastController } from "@ionic/core";
import { isPlatform } from "@ionic/react";
import { logEvent } from "@src/appV2/lib/analytics";
import { setZendeskMessagingUnread } from "@src/appV2/lib/ZendeskMessaging/utils";
import { getHumanReadableTag } from "@src/lib/utils";
import { SegmentCapacitorPlugin } from "capacitor-segment-plugin";
import { ZendeskSDK } from "capacitor-zendesk-sdk";
import moment from "moment-timezone";
import { FC, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { recordImpression, updatePushToken } from "./api";
import { getUrgentShiftsSegmentPayload, isZendeskNotification } from "./utils";
import { USER_EVENTS } from "../../constants/userEvents";
import { setToastStatus, setUnreadCount } from "../store/Notifications";

/**
 * These notifications shouldn't show up as a toast due to privacy reasons
 * They should only show up as phone notifications
 */
const NO_TOAST_NOTIFICATIONS = [
  "INITIAL_PAYMENT_DEBIT",
  "INITIAL_PAYMENT_BANK_ACCOUNT_SENT",
  "PAYOUT_COMPLETE",
  "ONE_OFF_PAYMENT_DEBIT",
  "ONE_OFF_PAYMENT_BANK",
];

const PushNotificationComponent: FC<{}> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Checks if notification has expired
   * @param ttlSeconds Time to live in seconds. (string)
   * @param createdTimeUTC Created time of notification (ISO string)
   */
  const isNotificationExpired = (ttlSeconds, createdTimeUTC) => {
    if (!ttlSeconds || !createdTimeUTC) {
      return false;
    }

    if (typeof ttlSeconds === "string") {
      ttlSeconds = parseInt(ttlSeconds, 10);
    }

    return moment().utc().isAfter(moment(createdTimeUTC).add(ttlSeconds, "second"));
  };

  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return;
    }
    PushNotifications.requestPermissions().then((result) => {
      setTimeout(() => {
        if (result.receive === "granted") {
          PushNotifications.register();
        }
      }, 100);
    });
  }, []);

  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return;
    }

    const onUpdateUnreadCount = (info: any) => {
      dispatch(setUnreadCount(info.unreadCount));
    };
    const updateCountBadgeListener = SegmentCapacitorPlugin.addListener(
      "updateCountBadge",
      onUpdateUnreadCount
    );

    return () => {
      updateCountBadgeListener.then((listener) => listener.remove());
    };
  }, [dispatch, history]);

  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return;
    }

    const onRegistration = ({ value: token }: Token) => {
      const existingToken = localStorage.getItem("pushToken");
      if (existingToken === token) {
        return;
      }

      localStorage.setItem("pushToken", token);
      updatePushToken(token);
      if (isPlatform("android")) {
        SegmentCapacitorPlugin.sendPushToken({ token });
      }
    };
    const removeListeners = (async () => {
      const registrationListener = await PushNotifications.addListener(
        "registration",
        onRegistration
      );

      const registrationErrorListener = await PushNotifications.addListener(
        "registrationError",
        (error: any) => {
          console.log("Error on registration: " + JSON.stringify(error));
        }
      );

      return () => {
        registrationListener.remove();
        registrationErrorListener.remove();
      };
    })();
    return () => {
      void removeListeners.then((listenerRemover) => listenerRemover());
    };
  }, [dispatch, history]);

  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return;
    }

    const onPushNotificationReceived = async (payload: PushNotificationSchema): Promise<void> => {
      if (payload.data.commute) {
        // Handled by LocationTracking component
        return;
      }

      const receivedAt = new Date().toISOString();

      const isNotificationFromZendesk = isZendeskNotification(payload);
      if (isNotificationFromZendesk) {
        setZendeskMessagingUnread(true);
      }

      const notificationArr = [
        {
          msg: payload.body as string,
          link: payload.data.link,
          shiftId: payload.data.shiftId,
          messageId: payload.data.messageId,
          method: payload.data.method,
        },
      ];

      logEvent(USER_EVENTS.MESSAGE_DELIVERED, {
        message: payload.body,
        message_name: `${
          getHumanReadableTag(payload.data.method)?.replace(" Notification", "") ?? ""
        } Notification`,
        to: "HCP",
        method: payload.data.method,
        channel: "push",
        notifyId: payload.data.messageId,
        ...getUrgentShiftsSegmentPayload({
          ...payload.data,
          createdAt: receivedAt,
        }),
      });

      const properties = {
        name: payload.data.method,
        medium: "Push",
        content: payload.body as string,
        source: "",
      };

      logEvent(USER_EVENTS.PUSH_NOTIFICATION_RECEIVED, properties);

      // Avoid showing toast message, in case the `payload.data.method` is missing.
      if (payload.data.method) {
        // Don't use a toast for some notifications, for privacy
        const shouldNotificationToastBeHidden = NO_TOAST_NOTIFICATIONS.includes(
          payload.data.method
        );
        if (!shouldNotificationToastBeHidden) {
          dispatch(setToastStatus(notificationArr, payload.id));
        }
      }
    };
    const pushNotificationReceivedListener = PushNotifications.addListener(
      "pushNotificationReceived",
      onPushNotificationReceived
    );

    return () => {
      void pushNotificationReceivedListener.then((listenerHandle) => listenerHandle.remove());
    };
  }, [dispatch, history]);

  useEffect(() => {
    if (!isPlatform("capacitor")) {
      return;
    }

    /**
     * Navigates user to the home page and shows
     * a toast message that notification has expired
     */
    const goToNotificationExpiredView = async () => {
      history.push("/home/openShifts");

      const toast = await toastController.create({
        message: "This notification has been expired",
        duration: 3000,
        position: "bottom",
      });
      await toast.present();
    };

    const onPushNotificationActionPerformed = async (payload: any) => {
      if (payload && payload.notification) {
        const { data } = payload.notification;
        const { ttlSeconds, createdTimeUTC, link, method, messageId } = data;

        const actionPerformedAt = new Date().toISOString();

        recordImpression(messageId, "push");

        logEvent(USER_EVENTS.MESSAGE_OPENED, {
          message_name: getHumanReadableTag(method),
          by: "Worker",
          message: payload.body,
          method: method,
          channel: "push",
          notifyId: messageId,
          ...getUrgentShiftsSegmentPayload({
            ...data,
            createdAt: actionPerformedAt,
          }),
        });

        const isClickedFromZendesk = isZendeskNotification(payload.notification);
        if (isClickedFromZendesk) {
          if (isPlatform("ios")) {
            await ZendeskSDK.showMessaging();
          }
          setZendeskMessagingUnread(false);
          return;
        }

        const isExpired = isNotificationExpired(ttlSeconds, createdTimeUTC);
        if (isExpired) {
          goToNotificationExpiredView();
        } else {
          history.push(link);
        }
      }
    };
    const pushNotificationActionPerformedListener = PushNotifications.addListener(
      "pushNotificationActionPerformed",
      onPushNotificationActionPerformed
    );

    return () => {
      void pushNotificationActionPerformedListener.then((listenerHandle) =>
        listenerHandle.remove()
      );
    };
  }, [dispatch, history]);

  return <Fragment />;
};

export { PushNotificationComponent };
